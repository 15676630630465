/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {GALLERY_TYPE, IGalleryControllerConfig, IProduct, ProductsManifest, VeloInputs} from '../types/galleryTypes';
import {
  BestSellersAlgorithmData,
  DEFAULT_COLLECTION_ID,
  Experiments,
  FORCE_RELATED_GALLERY_RENDER_TIMEOUT,
  GALLERY_PUBLIC_DATA_PRESET_ID,
  translationPath,
  USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME,
  SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI,
  RelatedProductsAlgorithmData,
} from '../constants';
import {IGalleryStyleParams, IPropsInjectedByViewerScript, ITextsMap} from '../types/sliderGalleryTypes';
import {ProductsService} from '../services/ProductsService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {getTranslations} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/utils';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {
  ActionStatus,
  AddToCartActionOption,
  APP_DEFINITION_ID,
  PageMap,
  PubSubEvents,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {MultilingualService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/MultilingualService/MultilingualService';
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';

import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/AddToCartService';
import {actualPrice, isPreOrder} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {CustomUrlApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/CustomUrl/CustomUrlApi';
import {BaseGalleryStore} from './BaseGalleryStore';
import {ProductPriceRange} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceRange/ProductPriceRange';
import {unitsTranslations} from '../common/components/ProductItem/ProductPrice/unitsTranslations';
import {ProductPriceBreakdown} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceBreakdown/ProductPriceBreakdown';
import _ from 'lodash';
import {ProductFilters} from '../graphql/queries-schema';
import {clickOnProductBoxSf, exposureEventForTests, viewGallerySf} from '@wix/bi-logger-ec-sf/v2';

export class SliderGalleryStore extends BaseGalleryStore {
  private readonly fedopsLogger;
  private mainCollectionId: string;
  private multilingualService: MultilingualService;
  private productPageSectionUrl: string;
  private translations: any = {};
  private isFedopsReport: boolean = true;
  private relatedProductIds: string[] = [];
  private addedToCartStatus: {[p: string]: ActionStatus} = {};
  private readonly customUrlApi: CustomUrlApi;
  private isUrlWithOverrides: boolean = false;

  constructor(
    styleParams: IGalleryStyleParams,
    config: IWidgetControllerConfig['config'],
    updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    siteStore: SiteStore,
    private readonly compId: string,
    private readonly reportError: (e) => any,
    private readonly type: string,
    private veloInputs?: VeloInputs
  ) {
    super({config}, siteStore, styleParams, updateComponent);
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    const {galleryColumns} = this.shouldUseYoshiStyleParamStandardInController ? this.styles : styleParams.numbers;
    const numOfColumns = galleryColumns || 4;

    const initialProductsCount = numOfColumns * 4;
    this.addToCartService = new AddToCartService(this.siteStore, this.config.publicData);
    this.productsService = new ProductsService(
      this.siteStore,
      initialProductsCount,
      'Slider Gallery',
      this.shouldShowProductOptions,
      this.shouldRenderPriceRange,
      this.fedopsLogger,
      false
    );
    this.customUrlApi = new CustomUrlApi(this.siteStore.location.buildCustomizedUrl);
    this.subscribe();
    this.handleUrlChange();
  }

  private get shouldRenderPriceRange(): boolean {
    return new ProductPriceRange(this.siteStore).shouldShowPriceRange();
  }

  public setVeloInputs(veloInputs: VeloInputs) {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
  }

  private handleUrlChange() {
    this.siteStore.location.onChange(async () => {
      await this.setInitialState();
    });
  }

  public async getCateogryProducts({limit, offset}: {limit: number; offset?: number}): Promise<void> {
    await this.productsService.getCategoryProducts({
      compId: this.compId,
      limit,
      offset,
    });
    this.productsManifest = this.generateProductsManifest(this.productsService.products);
    this.updateComponent({
      products: this.productsService.products,
      productsManifest: this.productsManifest,
      productsVariantInfoMap: this.productsVariantInfoMap,
    });
  }

  private padProductListWithPlaceholders() {
    if (this.productsService.products.length < this.productsService.totalCount) {
      const realLength = this.productsService.products.length;
      this.productsService.products[this.productsService.totalCount - 1] = undefined;
      this.productsService.products.fill({isFake: true} as any, realLength, this.productsService.totalCount);
    }
  }

  public async setInitialState(forceRender: boolean = false): Promise<void> {
    if (!forceRender && !this.shouldComponentRender()) {
      return this.updateComponent({
        isLoaded: false,
      });
    }

    const numOfColumns = this.config.style.styleParams.numbers.galleryColumns || 4;
    const numOfSets = 2;

    const fetcher = this.getDataFetcher();
    const [translations, response, {url}] = await Promise.all([
      getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale)),
      fetcher(),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]).catch(this.reportError);

    this.isUrlWithOverrides = await this.customUrlApi.init();

    if (this.galleryType === GALLERY_TYPE.COLLECTION && this.productsService.totalCount > numOfSets * numOfColumns) {
      this.padProductListWithPlaceholders();
      await this.productsService.getCategoryProducts({
        compId: this.compId,
        limit: numOfColumns * numOfSets,
        offset: this.productsService.totalCount - numOfColumns * numOfSets,
      });
    }

    const {products} = this.productsService;
    this.translations = translations;

    this.productPriceBreakdown = new ProductPriceBreakdown(this.siteStore, this.translations, {
      excludedPattern: 'gallery.price.tax.excludedParam.label',
      includedKey: 'gallery.price.tax.included.label',
      includedPattern: 'gallery.price.tax.includedParam.label',
      excludedKey: 'gallery.price.tax.excluded.label',
    });

    this.productPageSectionUrl = url;
    this.mainCollectionId = this.productsService.getMainCollectionId();
    this.productsManifest = this.generateProductsManifest(products);
    this.multilingualService = new MultilingualService(
      this.siteStore,
      this.publicData.COMPONENT,
      response.appSettings.widgetSettings
    );
    this.updateComponent(this.getPropsToInject(products));

    if (!this.siteStore.isSSR()) {
      this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    }
  }

  public onAppLoaded(): void {
    /* istanbul ignore next: hard to test it */
    if (this.isFedopsReport) {
      this.isFedopsReport = false;

      /* istanbul ignore next: debug */
      if (
        this.siteStore.experiments.enabled('specs.stores.ReportBiForEmptyGallery') &&
        !this.productsService.products.length
      ) {
        this.siteStore.webBiLogger.report(exposureEventForTests({testName: `empty-gallery-slider`}));
      }

      const {gallery_showPrice, showQuickView} = this.shouldUseYoshiStyleParamStandardInController
        ? this.styles
        : this.styleParams.booleans;

      const {gallery_hoverType} = this.shouldUseYoshiStyleParamStandardInController
        ? this.styles
        : this.styleParams.fonts;

      this.siteStore.webBiLogger.report(
        viewGallerySf({
          isMobileFriendly: this.siteStore.isMobileFriendly,
          addToCart: this.shouldShowAddToCartButton,
          hasPrice: gallery_showPrice,
          hasOptions: this.shouldShowProductOptions,
          hasQuantity: this.shouldShowQuantity,
          hasQuickView: showQuickView,
          hoverType: gallery_hoverType.value,
          priceBreakdown: this.productPriceBreakdown.priceBreakdownBIParam,
          navigationClick: this.getNavigationClick(),
          productsLogic: this.getProductsLogicForBi(),
          galleryInputId: this.getGalleryInputId(),
          productsList: this.productsService.products.map((product) => product.id).join(),
          algorithmId: this.publicData.COMPONENT.relatedProductsAlgorithmId,
          algorithmProviderId: this.publicData.COMPONENT.relatedProductsAppId,
        })
      );
    }
  }

  private getNavigationClick(): string {
    if (!this.shouldShowAddToCartButton) {
      return '';
    } else if (this.getAddToCartAction() === AddToCartActionOption.MINI_CART) {
      return 'mini-cart';
    } else if (this.getAddToCartAction() === AddToCartActionOption.CART) {
      return 'cart';
    }
    return 'none';
  }

  private getProductsLogicForBi(): string {
    switch (this.galleryType) {
      case GALLERY_TYPE.VELO_PRODUCTS:
        return SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI.VELO;
      case GALLERY_TYPE.COLLECTION:
        return this.mainCollectionId !== DEFAULT_COLLECTION_ID
          ? SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI.COLLECTION
          : SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI.ALL_PRODUCTS;
      case GALLERY_TYPE.RELATED_PRODUCTS:
        return SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI.RELATED_PRODUCTS;
      case GALLERY_TYPE.BEST_SELLERS:
        return SLIDER_GALLERY_PRODUCTS_LOGICS_FOR_BI.BEST_SELLERS;
    }
  }

  private getGalleryInputId(): string {
    switch (this.galleryType) {
      case GALLERY_TYPE.COLLECTION:
        return this.mainCollectionId;
      case GALLERY_TYPE.RELATED_PRODUCTS:
        return this.relatedProductIds.join(',');
    }
  }

  private getPropsToInject(products: IProduct[]): IPropsInjectedByViewerScript {
    return {
      ...this.getCommonPropsToInject(),
      allowFreeProducts: this.addToCartService.allowFreeProducts,
      addedToCartStatus: this.addedToCartStatus,
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      getCategoryProducts: this.getCateogryProducts.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      handleProductsOptionsChange: this.handleOptionSelectionsChange.bind(this),
      isFirstPage: true,
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      showShowLightEmptyState: this.productsService.hideGallery,
      hideGallery: this.productsService.hideGallery,
      shouldShowMobile: this.siteStore.isMobile(),
      isRTL: this.siteStore.isRTL(),
      mainCollectionId: this.mainCollectionId,
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      productsManifest: this.productsManifest,
      productsVariantInfoMap: this.productsVariantInfoMap,
      products,
      shouldShowAddToCartButton: this.shouldShowAddToCartButton,
      shouldShowQuantity: this.shouldShowQuantity,
      shouldShowProductOptions: this.shouldShowProductOptions,
      showTitle: this.shouldShowTitle,
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      textsMap: this.getTextsMap(),
      totalProducts: this.productsService.totalCount,
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      handleAddToCart: this.handleAddToCart.bind(this),
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
      productsPriceRangeMap: this.productsService.productPriceRangeMap,
      experiments: {
        isArrowlessMobileSliderEnabled: this.siteStore.experiments.enabled(
          Experiments.ClientGalleryArrowlessMobileSlider
        ),
        isCustomizePaddingEnabled: this.siteStore.experiments.enabled(Experiments.ShowGalleryCustomizePadding),
        newUiTpaImage: this.siteStore.experiments.enabled(Experiments.NewUiTpaImage),
        isAllowGalleryProductRoundCornersInViewer: this.siteStore.experiments.enabled(
          Experiments.AllowGalleryProductRoundCornersInViewer
        ),
        allowProductOptionsInSliderGallery: this.siteStore.experiments.enabled(
          Experiments.AllowProductOptionsInSliderGallery
        ),
        [USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME]: this.siteStore.experiments.enabled(
          Experiments.GalleryUseYoshiStyleParamStandard
        ),
        sliderGalleryInEditorX: this.siteStore.experiments.enabled(Experiments.SliderGalleryInEditorXViewer),
        sliderGalleryAutoGrid: this.siteStore.experiments.enabled(Experiments.SliderGalleryAutoGridViewer),
      },
    };
  }

  private getTextsMap(): ITextsMap {
    return {
      addToCartContactSeller: this.translations['gallery.contactSeller.button'],
      addToCartOutOfStock:
        this.multilingualService.get('gallery_oosButtonText') || this.translations['gallery.outOfStock.button'],
      addToCartSuccessSR: this.translations['gallery.sr.addToCartSuccess'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
      galleryAddToCartButtonText:
        this.multilingualService.get('gallery_addToCartText') || this.translations['gallery.addToCart.button'],
      galleryAddToCartPreOrderButtonText:
        this.multilingualService.get('gallery_preOrderText') || this.translations['gallery.preOrder.button'],
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      productOutOfStockText:
        this.multilingualService.get('gallery_oosButtonText') || this.translations.OUT_OF_STOCK_LABEL,
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quantityAddSR: this.translations['gallery.sr.addQty'],
      quantityChooseAmountSR: this.translations['gallery.sr.chooseQty'],
      quantityInputSR: this.translations['gallery.sr.quantity'],
      quantityMaximumAmountSR: this.translations['gallery.exceedsQuantity.error'],
      quantityMinimumAmountSR: this.translations['gallery.minimumQuantity.error'],
      quantityRemoveSR: this.translations['gallery.sr.removeQty'],
      quantityTotalSR: this.translations['gallery.sr.totalQty'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      sliderGalleryNextProduct: this.translations['sr.sliderGallery.nextProduct'],
      sliderGalleryNoProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MINI_GALLERY_MAIN,
      sliderGalleryPreviousProduct: this.translations['sr.sliderGallery.previousProduct'],
      sliderGalleryTitle: this.title,
      priceRangeText: this.translations['gallery.price.from.label'],
      pricePerUnitSR: this.translations['gallery.sr.units.basePrice.label'],
      arrowPrevious: this.translations['gallery.sr.carousel.previous.label'],
      carouselContainerLabel: this.translations['gallery.sr.carousel.container.label'],
      arrowNext: this.translations['gallery.sr.carousel.next.label'],
      measurementUnits: this.getMeasurementUnitsTranslation(),
    };
  }

  private getMeasurementUnitsTranslation() {
    return _.reduce(
      unitsTranslations,
      (result, types, unit) => {
        result[unit] = result[unit] || {};
        _.each(types, (translationKey, type) => {
          result[unit][type] = this.translations[translationKey];
        });
        return result;
      },
      {}
    );
  }

  private generateProductsManifest(products: IProduct[]): ProductsManifest {
    return products
      .filter(({urlPart}) => Boolean(urlPart))
      .reduce((acc, product) => {
        acc[product.id] = {
          url: this.getProductPageUrl(product.urlPart),
          addToCartState: this.addToCartService.getButtonState({
            price: actualPrice(product),
            inStock: product.isInStock,
            isPreOrderState: isPreOrder(product),
          }),
        };
        return acc;
      }, {});
  }

  private getProductPageUrl(slug) {
    return /* istanbul ignore next: test */ this.isUrlWithOverrides
      ? this.customUrlApi.buildProductPageUrl({slug})
      : `${this.productPageSectionUrl}/${slug}`;
  }

  private async handleAddToCart({productId, index, quantity}: {productId: string; index: number; quantity: number}) {
    await this.productsService.addToCart({
      productId,
      quantity,
      index,
      compId: this.compId,
      externalId: this.config.externalId,
      addToCartAction: this.getAddToCartAction(),
    });

    this.updateAddToCartStatus(productId, ActionStatus.SUCCESSFUL);
  }

  private readonly getAddToCartAction = () =>
    this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_addToCartAction
      : this.styleParams.numbers.gallery_addToCartAction;

  private readonly updateAddToCartStatus = (productId: string, status: ActionStatus) => {
    this.addedToCartStatus = {
      ...this.addedToCartStatus,
      [productId]: status,
    };

    this.updateComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private openQuickView({productId, index, selectionIds}: {productId: string; index: number; selectionIds?: number[]}) {
    const galleryProductsLogic = this.getProductsLogicForBi();
    const params = {
      compId: this.compId,
      externalId: this.config.externalId,
      galleryType: 'slider-gallery',
      galleryProductsLogic,
      galleryInputId: this.getGalleryInputId(),
    };
    if (this.shouldShowProductOptions) {
      return this.productsService.quickViewProduct(productId, index, {
        ...params,
        selectionIds,
      });
    }

    return this.productsService.quickViewProduct(productId, index, {
      ...params,
    });
  }

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);

    this.siteStore.webBiLogger.report(
      clickOnProductBoxSf({
        productId,
        hasRibbon: !!product.ribbon,
        hasOptions: !!product.options.length,
        index,
        productType: product.productType,
        galleryType: 'slider-gallery',
        galleryProductsLogic: this.getProductsLogicForBi(),
        galleryInputId: this.getGalleryInputId(),
        rank: index,
      })
    );

    this.siteStore.navigate(
      {
        sectionId: PageMap.PRODUCT,
        state: product.urlPart,
        queryParams: undefined,
      },
      true
    );
    this.productsService.sendClickTrackEvent(product, index);
  }

  public async updateState(
    newStyleParams: IGalleryStyleParams,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    this.config.publicData = newPublicData;
    this.multilingualService.setPublicData(newPublicData.COMPONENT);
    const nextStyleParams = getStyleParamsWithDefaults(newStyleParams, () =>
      getStyleParamsWithDefaultsFunc({style: {styleParams: newStyleParams}, dimensions: undefined})
    );

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    }

    let shouldUpdateWithOptions: boolean;
    if (this.shouldUseYoshiStyleParamStandardInController) {
      const oldStyles = this.styles;
      this.updateStyles(newStyleParams);
      shouldUpdateWithOptions = this.shouldUpdateProductOptions(oldStyles);
    } else {
      shouldUpdateWithOptions = this.shouldUpdateProductOptionsOld(nextStyleParams);
      this.styleParams = nextStyleParams;
    }

    if (shouldUpdateWithOptions) {
      this.updateWithOptions();
      await this.setInitialState(true);
    }

    this.updateComponent({
      ...this.getCommonPropsToUpdate(),
      showTitle: this.shouldShowTitle,
      textsMap: this.getTextsMap(),
      productsPriceRangeMap: this.productsService.productPriceRangeMap,
      shouldShowAddToCartButton: this.shouldShowAddToCartButton,
      shouldShowQuantity: this.shouldShowQuantity,
      shouldShowProductOptions: this.shouldShowProductOptions,
    });
  }

  private get galleryType() {
    if (this.veloInputs) {
      return GALLERY_TYPE.VELO_PRODUCTS;
    }

    if (
      this.siteStore.experiments.enabled(Experiments.GalleryStorefrontProductRecommendations) &&
      this.publicData.COMPONENT.presetId === GALLERY_PUBLIC_DATA_PRESET_ID.BEST_SELLERS
    ) {
      return GALLERY_TYPE.BEST_SELLERS;
    }
    if (this.publicData.COMPONENT.presetId === GALLERY_PUBLIC_DATA_PRESET_ID.RELATED_PRODUCTS) {
      return GALLERY_TYPE.RELATED_PRODUCTS;
    }

    return GALLERY_TYPE.COLLECTION;
  }

  private getDataFetcher() {
    const dataFetcher = {
      [GALLERY_TYPE.COLLECTION]: () =>
        this.productsService.oldGetInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
        }),
      [GALLERY_TYPE.RELATED_PRODUCTS]: () =>
        this.siteStore.experiments.enabled(Experiments.GalleryStorefrontProductRecommendations)
          ? this.productsService.getRelatedProductsByAlgorithm({
              externalId: this.config.externalId,
              productIds: this.relatedProductIds,
              algorithmId: this.publicData.COMPONENT.relatedProductsAlgorithmId ?? RelatedProductsAlgorithmData.id,
              appId: this.publicData.COMPONENT.relatedProductsAppId ?? RelatedProductsAlgorithmData.appId,
            })
          : this.productsService.getRelatedItems({
              externalId: this.config.externalId,
              productIds: this.relatedProductIds,
            }),
    };

    if (this.siteStore.experiments.enabled(Experiments.GalleryStorefrontProductRecommendations)) {
      dataFetcher[GALLERY_TYPE.BEST_SELLERS] = () =>
        this.productsService.getRelatedProductsByAlgorithm({
          externalId: this.config.externalId,
          productIds: this.relatedProductIds,
          algorithmId: BestSellersAlgorithmData.id,
          appId: BestSellersAlgorithmData.appId,
        });
    }

    if (this.veloInputs) {
      dataFetcher[GALLERY_TYPE.VELO_PRODUCTS] = () => {
        return this.productsService.getInitialData({
          externalId: this.config.externalId,
          compId: this.compId,
          sort: null,
          filters: this.veloInputs?.productIds
            ? ({
                term: {
                  field: 'id',
                  op: 'IN',
                  values: this.veloInputs.productIds,
                },
              } as ProductFilters)
            : null,
          limit: null,
          offset: 0,
          mainCollectionId: this.veloInputs?.collectionId ? this.veloInputs.collectionId : this.mainCollectionId,
        });
      };
    }

    return dataFetcher[this.galleryType];
  }

  private subscribe() {
    const forceRenderWhenEmpty = () => this.relatedProductIds.length === 0 && this.renderRelatedProducts();

    if (this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS || this.galleryType === GALLERY_TYPE.BEST_SELLERS) {
      setTimeout(forceRenderWhenEmpty, FORCE_RELATED_GALLERY_RENDER_TIMEOUT);
      this.siteStore.pubSub.subscribe(
        PubSubEvents.RELATED_PRODUCTS,
        (response) => this.renderRelatedProducts(response.data),
        true
      );
    }
  }

  private renderRelatedProducts(productIds: string[] = []) {
    this.relatedProductIds = [...productIds];
    this.setInitialState(true);
  }

  private shouldComponentRender() {
    if (!this.siteStore.isInteractive()) {
      return true;
    }
    return !(this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS && this.relatedProductIds.length === 0);
  }

  private get title(): string {
    if (this.galleryType === GALLERY_TYPE.COLLECTION) {
      const collectionName =
        this.productsService.getMainCollectionId() === DEFAULT_COLLECTION_ID
          ? this.translations['gallery.collection.allProducts']
          : this.productsService.collectionName;

      return this.multilingualService.get('SLIDER_GALLERY_TITLE_COLLECTION') || collectionName;
    }

    if (
      this.siteStore.experiments.enabled(Experiments.GalleryStorefrontProductRecommendations) &&
      this.galleryType === GALLERY_TYPE.BEST_SELLERS
    ) {
      return (
        this.multilingualService.get('SLIDER_GALLERY_TITLE_BEST_SELLERS') ||
        this.translations['gallery.bestSellers.default.title']
      );
    }

    return (
      this.multilingualService.get('SLIDER_GALLERY_TITLE_RELATED_PRODUCTS') ||
      this.translations['gallery.relatedProducts.default.title']
    );
  }

  private get shouldShowAddToCartButton() {
    return this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_showAddToCartButton
      : this.styleParams.booleans.gallery_showAddToCartButton;
  }

  private get shouldShowQuantity() {
    return this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_showAddToCartQuantity
      : this.styleParams.booleans.gallery_showAddToCartQuantity;
  }

  protected get shouldShowProductOptions(): boolean {
    if (!this.siteStore.experiments.enabled(Experiments.AllowProductOptionsInSliderGallery)) {
      return false;
    }
    return this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_showProductOptionsButton
      : this.styleParams.booleans.gallery_showProductOptionsButton;
  }

  private shouldUpdateProductOptions(oldStyles): boolean {
    if (!this.siteStore.experiments.enabled(Experiments.AllowProductOptionsInSliderGallery)) {
      return false;
    }
    return this.styles.gallery_showProductOptionsButton !== oldStyles.gallery_showProductOptionsButton;
  }

  /* istanbul ignore next: will be removed when specs.stores.GalleryUseYoshiStyleParamStandardInController is merged */
  private shouldUpdateProductOptionsOld(nextStyleParams): boolean {
    if (!this.siteStore.experiments.enabled(Experiments.AllowProductOptionsInSliderGallery)) {
      return false;
    }
    return (
      this.styleParams.booleans.gallery_showProductOptionsButton !==
      nextStyleParams.booleans.gallery_showProductOptionsButton
    );
  }

  private updateWithOptions() {
    this.productsService.setWithOptions(this.shouldShowProductOptions);
  }

  private get shouldShowTitle() {
    const {gallery_showTitle} = this.shouldUseYoshiStyleParamStandardInController
      ? this.styles
      : this.styleParams.booleans;

    if (gallery_showTitle !== undefined) {
      return gallery_showTitle;
    }
    return this.galleryType === GALLERY_TYPE.RELATED_PRODUCTS || this.galleryType === GALLERY_TYPE.BEST_SELLERS;
  }
}
